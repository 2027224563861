<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">新增訂單</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input label="用戶名稱" :fieldValue.sync="formData.student_name" required readonly />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="電話" :fieldValue.sync="formData.phone" required type="tel" readonly />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="課堂類型"
                :fieldValue.sync="formData.lesson_category"
                :options="$lessonCategoryOptions"
                required
                readonly
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="科目"
                :fieldValue.sync="formData.subject"
                :options="$subjectOptions"
                required
                readonly
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-input
                label="堂數"
                :fieldValue.sync="formData.lesson_count"
                required
                type="number"
                isIntegerOnly
                readonly
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-input
                label="價錢( HK$)/ 堂"
                :fieldValue.sync="formData.price_per_lesson"
                required
                type="number"
                :customRules="[v => ($validate.isNumber(v) && parseFloat(v) > 0) || '價錢必須大於0']"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-select label="使用優惠" :fieldValue.sync="formData.coupon" :options="couponOptions" enabledClear />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="付款方式"
                :fieldValue.sync="formData.payment_method"
                :options="paymentOptions"
                required
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div class="d-flex justify-end">
        <v-btn
          :disabled="!targetJob"
          depressed
          color="warning accent-4"
          class="white--text"
          type="submit"
          :loading="$store.getters.isLoading"
          >總額：${{ $Formatter.separator(orderTotal) }}</v-btn
        >
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'

export default {
  name: 'CreateOrder',
  components: {
    FormInput,
    FormSelect,
    FormButtonUpload,
    FormDatePicker,
  },
  computed: {
    studentId() {
      const id = parseInt(this.$route.params.studentId)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    jobId() {
      const id = parseInt(this.$route.params.jobId)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    orderTotal() {
      if (
        this.$validate.regexFloating(this.formData.price_per_lesson) &&
        this.$validate.regexNumber(this.formData.lesson_count)
      ) {
        let amount = parseFloat(this.formData.price_per_lesson) * parseInt(this.formData.lesson_count)
        if (this.$validate.DataValid(this.formData.coupon)) {
          const found = this.couponOptions.find(el => el.value === this.formData.coupon)
          if (found.data) {
            if (found.data.discount_type === 'percentage') {
              amount = amount * (100 - found.data.discount_value) * 0.01
            } else if (found.data.discount_type === 'price') {
              amount -= found.data.discount_value
            }
          }
        }

        return amount
      }

      return 0
    },
  },
  data: () => ({
    formData: {
      student_name: '',
      phone: '',
      lesson_category: null,
      subject: null,
      lesson_count: null,
      price_per_lesson: null,
      coupon: null,
      payment_method: null,
    },
    paymentOptions: [
      { text: '信用卡', value: 'credit_card' },
      { text: '轉帳', value: 'bank_transfer' },
    ],
    studentOptions: [],
    couponOptions: [],
    targetJob: null,
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getStudent() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetStudentById(this.studentId, user.id, user.token)
        this.formData.student_name = data.name
        this.formData.phone = data.phone
        await this.getJob()
      } catch (err) {
        this.$common.error(err)
        this.setAlert({
          type: 'error',
          message: '找不到學生',
          redirect: 'OrderList',
        })
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },
    async getJob() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetJobById(this.jobId, user.id, user.token)

        if (data.edit_timetable_only) {
          this.setAlert({
            type: 'error',
            message: '此工作已建立訂單',
            redirect: 'OrderList',
          })
          return
        }
        this.targetJob = data

        this.formData.lesson_category = data.lesson_category
        this.formData.subject = data.subject.length ? data.subject[0] : null
        this.formData.lesson_count = data.course_timetable.length
      } catch (err) {
        this.$common.error(err)
        this.setAlert({
          type: 'error',
          message: '找不到相關課堂工作',
          redirect: 'OrderList',
        })
      }
    },

    async getCoupons() {
      try {
        const user = this.getCurrentUserData()
        const { data } = await this.$Fetcher.GetCoupons({ status: 'active' }, user.id, user.token)
        const valid = data.filter(item => {
          if (item.status !== 'active' || item.data_status !== 'active') {
            return false
          }

          const start_date = item.start_date ? this.$Formatter.convertStrToDate(item.start_date) : null
          const end_date = item.expiry_date ? this.$Formatter.convertStrToDate(item.expiry_date) : null

          if (start_date && end_date) {
            if (
              this.$Formatter.today.getTime() < start_date.getTime() ||
              this.$Formatter.today.getTime() > end_date.getTime()
            ) {
              return false
            }
          } else if (start_date) {
            if (this.$Formatter.today.getTime() < start_date.getTime()) {
              return false
            }
          } else if (end_date) {
            if (this.$Formatter.today.getTime() > end_date.getTime()) {
              return false
            }
          }

          return true
        })
        this.couponOptions = valid.map(item => {
          return { text: `${item.code} ${item.name}`, value: item.id, data: item }
        })
      } catch (err) {
        this.$common.error(err)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }

      if (!this.targetJob) {
        this.$store.dispatch('toggleSnack', {
          type: 'error',
          message: '找不到相關課堂工作',
        })
      }

      let payload = {
        job_request_id: this.jobId,
        payment_method: this.formData.payment_method,
        lesson_price: parseFloat(this.formData.price_per_lesson),
        promo_code: '',
        course_count: parseInt(this.formData.lesson_count),
        total: this.orderTotal,
      }

      if (this.$validate.DataValid(this.formData.coupon)) {
        const found = this.couponOptions.find(el => el.value === this.formData.coupon)
        payload.promo_code = found.data.code
      }

      const user = this.getCurrentUserData()
      this.$store.dispatch('setLoading', true)
      try {
        const orderId = await this.$Fetcher.NewOrder(payload, user.id, user.token)

        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('toggleSnack', {
          type: 'success',
          message: '新增成功',
          redirect: { name: 'OrderDetail', params: { id: orderId } },
        })
      } catch (err) {
        this.$common.error(err)
        this.setAlert({
          title: '新增失敗',
          message: err,
          type: 'error',
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    this.getCoupons()
    await this.getStudent()
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
